export const OS_TYPES = [
    {
        value: 1,
        label: "Windows"
    },
    {
        value: 2,
        label: "macOS"
    },
    {
        value: 3,
        label: "Linux"
    },
    {
        value: 4,
        label: "iOS"
    },
    {
        value: 5,
        label: "Android"
    }
]; 